// TODO: CLEAN/REWRITE
.ant-form-item {
  @apply mb-5;
  .ant-form-item-label > label {
    @apply font-bold text-sm text-gray-600;
  }

  input.ant-input {
    @apply h-[2.875rem] py-3 pl-4 border-gray-300 rounded-sm hover:border-gray-400 focus:border-blue-500 focus:outline-blue-200;
  }

  .ant-form-item-control-input-content {
    .ant-input-status-error {
      @apply  border-red-300 hover:border-red-300;
    }
  }

  .ant-form-item-explain-error {
    @apply text-xs text-red-300;
  }
}
