@import "./fonts/index.css";

@import "./antd";

@import "./swiper";
@import "./nprogress";

@import "./tailwind/base.css";
@import "./tailwind";

html {
  font-size: 16px;
  font-family: 'ZeissMYingHei','Noto Sans SC',Consolas,Menlo,Courier,monospace;
}

#__next {
  @apply h-full;
}
