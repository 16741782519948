.ant-tabs {
  .ant-tabs-nav-wrap {
    .ant-tabs-nav-list {
      .ant-tabs-tab {
        @apply relative pb-3 text-sm font-light text-gray-600 before:absolute before:bottom-0 before:h-0 before:w-full before:bg-blue-600  hover:text-blue-600 hover:before:h-[2px];
      }
      .ant-tabs-tab-active .ant-tabs-tab-btn {
        @apply font-bold text-gray-600;
      }
      .ant-tabs-tab + .ant-tabs-tab {
        margin: 0 0 0 20px;
      }
    }
    .ant-tabs-ink-bar {
      @apply bg-gray-600;
      height: 2px;
    }
  }
}
.ant-tabs-top {
  .ant-tabs-nav {
    margin: 0;
  }
}
@media (min-width: 1025px) {
  .ant-tabs {
    .ant-tabs-nav-wrap {
      .ant-tabs-nav-list {
        .ant-tabs-tab {
          @apply pb-4 text-base;
        }
        .ant-tabs-tab + .ant-tabs-tab {
          margin: 0 0 0 32px;
        }
      }
    }
  }
  .ant-tabs-top {
    .ant-tabs-nav {
      margin: 0;
    }
  }
}
