.ant-steps {
  .ant-steps-item.ant-steps-item-active {
    .ant-steps-item-title {
      @apply font-bold;
    }
  }

  &.ant-steps-default {
    .ant-steps-item-icon {
      @apply w-7 h-7;
      font-size: 16px;
      line-height: 28px;
      margin-inline-end: 12px;

      .ant-steps-icon {
        top: -1.5px;
      }
    }

    .ant-steps-item-title {
      line-height: 28px;
    }
  }
}
