.ant-checkbox-wrapper {
  @apply text-gray-600;
}

.ant-checkbox {
  .ant-checkbox-inner {
    @apply border-gray-300;
  }

  &.ant-checkbox-checked .ant-checkbox-inner {
    @apply border-blue-600;
  }
}

.ant-checkbox-checked.ant-checkbox-disabled {
  .ant-checkbox-inner {
    @apply bg-blue-500 opacity-40;

    &:after {
      @apply border-white;
    }
  }

  & + span {
    @apply text-gray-600;
  }
}
