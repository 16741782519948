.popover {
  width: 300px;
  padding: 20px 20px 24px;
  position: absolute;
  background: #ffffff;
  filter: drop-shadow(2px 4px 16px rgba(25, 25, 25, 0.08));
  border-radius: 8px;
  right: 86px;
  bottom: 0;
  z-index: 1000;
  &::after {
    content: "";
    width: 0;
    height: 0;
    border: 12px solid transparent;
    border-left-color: #FFFFFF;
    position: absolute;
    bottom: 20px;
    right: -20px;
  }
}
.popoverClearPosition {
  position: relative;
  inset: auto;
  &::after {
    content: "";
    display: none;
  }
}
