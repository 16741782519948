.ant-notification{
  top:96px !important;
  z-index:40 !important;
}
.product-cart-notification {
  width: fit-content;
  z-index: 40;
  box-shadow: 0 2px 8px rgba(50, 55, 62, 0.04);
  @apply w-fit border border-gray-300 rounded-sm py-[1.125rem] px-5;

  .ant-notification-notice-close {
    @apply hidden;
  }

  .ant-notification-notice-with-icon .ant-notification-notice-description {
    margin-inline-start: 1.5rem;
  }
  .ant-notification-notice-with-icon .ant-notification-notice-message {
    @apply m-0;
  }
}
