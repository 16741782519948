@font-face {
    font-family: 'ZeissMYingHei';
    src: url('MYingHei-Regular.eot?#iefix') format('embedded-opentype'),
    url('MYingHei-Regular.woff2') format('woff2'),
    url('MYingHei-Regular.woff') format('woff'),
    url('MYingHei-Regular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'ZeissMYingHei';
    src: url('MYingHei-Bold.eot?#iefix') format('embedded-opentype'),
    url('MYingHei-Bold.woff2') format('woff2'),
    url('MYingHei-Bold.woff') format('woff'),
    url('MYingHei-Bold.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}
