.ant-collapse {
  @apply bg-white border-0;

  .ant-collapse-content {
    @apply border-t-0;
  }

  &>.ant-collapse-item {
    @apply border-b-0;
  }

  .ant-collapse-item >.ant-collapse-header {
    @apply px-0 text-gray-600 font-bold;
  }

  .ant-collapse-content>.ant-collapse-content-box {
    @apply px-0 py-3;
  }
}
