.ant-table-wrapper{
  -webkit-overflow-scrolling: touch;
  .ant-table{
    font-family: 'ZeissMYingHei','Noto Sans SC',Consolas,Menlo,Courier,monospace;
    @apply text-sm text-gray-600;

    &.ant-table-ping-right {
      .ant-table-tbody > tr.ant-table-row:hover > td.ant-table-cell-fix-right {
        @apply bg-white;
      }
    }
  }
  .ant-table-thead>tr>th, .ant-table-tbody>tr>td {
    @apply pl-3 pr-1;
  }
  @media (min-width: 1025px) {
    .ant-table-thead>tr>th, .ant-table-tbody>tr>td {
      @apply pl-5 pr-2;
    }
  }
  td.ant-table-column-sort {
    @apply bg-white;
  }
  .ant-table-tbody>tr>td {
    @apply py-3;
  }
  .ant-table-column-sorters {
    @apply justify-start;
  }
  .ant-table-column-title {
    @apply flex-none;
  }
  .ant-table-column-sorter {
    margin-inline-start: 6px;
  }
  .ant-table-thead {
    & > tr > th{
      @apply bg-gray-20 border-0 font-light text-gray-600;
      &::before{
        @apply hidden;
      }
    }
    th.ant-table-column-has-sorters.ant-table-cell-fix-right {
      @apply bg-gray-20;
      &:hover {
        @apply bg-gray-20;
      }
    }
    th.ant-table-column-sort {
      .ant-table-column-sorters {
        @apply justify-start;
      }
    }
  }
  .ant-table-tbody {
    & .ant-table-row.ant-table-row-selected >.ant-table-cell {
      @apply bg-white;
    }
    & > tr.ant-table-row:hover {
      & > td {
        @apply bg-gray-20 bg-opacity-40 border-0 border-b border-b-gray-200;
      }
    }
    & > tr > td.ant-table-cell-fix-right:hover{
      @apply bg-gray-20 bg-opacity-100;
    }
    & > tr > td.ant-table-cell:hover {
      @apply bg-gray-20 bg-opacity-40;
    }
    & > tr > td {
      @apply border-0 border-b border-b-gray-200;
    }
    & >tr.ant-table-row-selected >td {
      @apply bg-white;
    }
  }
  .ant-table-sticky-scroll {
    height: 12px !important;
    @apply h-3 border border-gray-500 rounded-full;
  }
  .ant-table-sticky-scroll-bar {
    height: 10px !important;
    @apply bg-gray-600 rounded-full;
  }
}
