.ant-tag {
  @apply text-blue-400 px-2 py-1 mb-2 bg-blue-100 border border-blue-400 rounded-none;

  .ant-tag-close-icon {
    @apply w-2 inline-block;
    margin-inline-start: 10px;
    svg {
      @apply inline-block transform origin-left scale-50 fill-blue-400 -mt-0.5;
    }
  }
}
