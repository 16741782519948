.logo {
  @apply grow;
}

.menus {
  flex-grow: 4;
  @apply mt-6;

  .menuType {
    line-height: 54px;
  }

  svg {
    top: 21px;
  }

  .menuItem {
    div {
      line-height: 46px;
    }
  }
}

.mediaCodes {
  @apply grow;
  @apply justify-end;
  @apply flex;
  @apply items-center;
}

.footer {
  height: 76px;
  @apply border-t;
  @apply border-solid;
  @apply border-t-gray-300;

  &.wrapFooter {
    height: 68px;
  }
}

@screen lg {
  .menus {
    @apply mt-0;

    .menuType {
      line-height: inherit;

      &:first-of-type {
        @apply mt-0;
      }
    }

    .menuItem {
      div {
        line-height: 22px;
        @apply mt-4;

        &:first-of-type {
          @apply mt-5;
        }
      }
    }
  }
  .footer {
    height: 74px;
  }
}


