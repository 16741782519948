.buttonContainer {
  @apply inline-flex justify-center items-center rounded-sm;
}

.loadingContainer {
  @apply inline-flex items-center justify-center;
  svg {
    animation: rotating 1.5s linear infinite;
  }
}

@keyframes rotating {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
