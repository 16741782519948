.swiperCommon {
  .swiper-wrapper {
    margin-bottom: 34px;
  }

  .swiper-pagination-bullets.swiper-pagination-horizontal {
    display: flex;
    justify-content: center;
    align-items: center;
    bottom: -34px;

    .swiper-pagination-bullet {
      width: 47px;
      height: 3px;
      display: block;
      border-top-width: 1px;
      border-bottom-width: 1px;
      margin: 0;

      @apply border-y-white bg-gray-300;
      border-radius: 0;
      opacity: 1;

      &:hover {
        width: 48px;
        @apply border-y-gray-300 bg-gray-300;
      }
      &:focus {
        @apply border-y-gray-300 bg-gray-300;
      }
    }

    .swiper-pagination-bullet-active {
      width: 48px;
      height: 3px;
      margin: 0 4px;
      @apply border-y-gray-600 bg-gray-600;
      &:hover {
        width: 48px;
        height: 3px;
        @apply border-y-gray-600 bg-gray-600;
      }
    }
  }
}

@media (min-width: 1025px) {
  .swiperCommon {
    .swiper-wrapper {
      margin-bottom: 53px;
    }

    .swiper-pagination-bullets.swiper-pagination-horizontal {
      bottom: -53px;
      .swiper-pagination-bullet {
        width: 80px;
        height: 5px;
        border-top-width: 2px;
        border-bottom-width: 2px;

        &:hover {
          width: 80px;
          height: 5px;
        }
      }

      .swiper-pagination-bullet-active {
        width: 80px;
        height: 5px;
        &:hover {
          width: 80px;
          height: 5px;
        }
      }
    }
  }
}
