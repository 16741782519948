.ant-message-notice .ant-message-notice-content {
    @apply shadow-sm;
    @apply text-xs;
    @apply p-3;
    @apply text-gray-600;
    @apply rounded-sm;
    @apply border-solid;
    @apply border;
    @apply border-gray-300;

}
.ant-message-notice .ant-message-error .anticon {
    @apply text-red-300;
}

#toast-slot {
    @apply relative;
    .ant-message {
        @apply absolute;
    }
}
