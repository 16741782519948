.ant-pagination {
  justify-content: center !important;
  @apply pt-4;
  .ant-pagination-item {
    @apply mx-4;
    a {
      @apply text-gray-600;
    }
    a:hover {
      @apply text-gray-600;
    }
  }
  .ant-pagination-item-active {
    @apply border-0 border-b-2 border-blue-500 mx-4;
    a {
      @apply text-gray-600 font-medium;
    }
  }
  .ant-pagination-prev{
    @apply flex items-center justify-center;
  }
  .ant-pagination-next{
    @apply flex items-center justify-center;
  }
}
@media (min-width: 1025px) {
  .ant-pagination {
    @apply pt-5;
  }
}
