@mixin abs-center($direction: 'both') {
  @apply absolute transform;

  @if $direction == 'vertical' {
    @apply top-1/2 -translate-y-1/2;
  } @else if $direction == 'horizontal' {
    @apply left-1/2 -translate-x-1/2;
  } @else {
    @apply left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2;
  }
}

@layer components {
  .abs-v-center {
    @include abs-center('vertical');
  }

  .abs-h-center {
    @include abs-center('horizontal');
  }

  .abs-center {
    @include abs-center();

    &_ie {
      @include abs-center();
      -ms-transform: translate(-50%, -50%);
    }
  }

  .abs-full {
    @apply absolute left-0 top-0 w-full h-full;
  }
}
