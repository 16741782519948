.firstCategoryWrapper {
  .firstCategory {
    &.active {
      @apply bg-gray-100 font-bold;
      display: flex;
    }
  }
}

.secondCategoryWrapper {
  width: 100%;

  &.active {
    display: block;
  }

  .secondItem {
    width: calc((100% - 16px) / 2);
    &:not(:first-of-type) {
      margin-left: 16px;
    }
  }

  .row {
    width: 100%;
  }

  .firstRow {
    margin-top: 0;
  }

  @media (min-width: 1301px) {
    .secondItem {
      width: calc((100% - 60px) / 3);
  
      &:not(:first-of-type) {
        margin-left: 30px;
      }
    }
  }
  
}

.overlay {
  background-color: rgba(50, 55, 62, 0.4);
  height:100%
}


