.swiperCommon {
  &.swiper {
    overflow: visible;
  }
  .swiper-button-next,
  .swiper-button-prev,
  .swiper-button-disabled {
    background-color: rgba(50, 55, 62, 0.4);
    border-radius: 50%;
    width: 32px;
    height: 32px;

    &:hover,
    &:focus {
      background-color: rgba(50, 55, 62, 0.6);
    }
  }

  .swiper-button-next.swiper-button-disabled,
  .swiper-button-prev.swiper-button-disabled {
    background-color: rgba(220, 227, 233, 0.6);
  }

  .swiper-button-prev {
    left: -16px;
    transform: translateY(16px);
  }

  .swiper-button-next {
    right: -16px;
    transform: translateY(16px);
  }

  .swiper-button-prev::after,
  .swiper-button-next::after {
    content: '';
    background-image: url('../../../public/arrow-right.svg');
    background-size: cover;
    width: 16px;
    height: 16px;
    display: block;
  }

  .swiper-button-prev::after {
    transform: rotate(180deg);
  }

  .swiper-button-prev,
  .swiper-button-next,
  .swiper-button-prev.swiper-button-disabled,
  .swiper-button-next.swiper-button-disabled {
    opacity: 1;
    pointer-events: auto;
  }
}

@media (min-width: 1025px) {
  .swiperCommon {
    .swiper-button-next,
    .swiper-button-prev,
    .swiper-button-disabled {
      width: 48px;
      height: 48px;
    }

    .swiper-button-prev {
      left: -48px;
      transform: translateY(24px);
    }

    .swiper-button-next {
      right: -48px;
      transform: translateY(24px);
    }

    .swiper-button-prev,
    .swiper-button-next,
    .swiper-button-prev.swiper-button-disabled,
    .swiper-button-next.swiper-button-disabled {
      opacity: 0;
    }
    &:hover {
      .swiper-button-prev,
      .swiper-button-next,
      .swiper-button-prev.swiper-button-disabled,
      .swiper-button-next.swiper-button-disabled {
        opacity: 1;
      }
    }
  }
}
