.ant-radio-wrapper {
  .ant-radio-inner {
    @apply w-4 h-4;
  }

  .ant-radio-disabled {
    .ant-radio-inner {
      @apply bg-blue-500 opacity-40;
  
      &:after {
        @apply bg-white;
      }
    }
  
    & + span {
      @apply text-gray-600;
    }
  }

  .ant-radio-checked {
    &::after {
      @apply animate-none;
    }

    .ant-radio-inner {
      @apply bg-white;
      &::after {
        @apply scale-[0.495] transform bg-blue-500;
      }
    }
  }
}
