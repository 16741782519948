.avatar {
  position: relative;
  .menus {
    @apply bg-white shadow;
    z-index: -1;
    opacity: 0;
    width: 118px;
    padding: 10px 8px;
    position: absolute;
    right: 0;
    top: 54px;
    border-radius: 4px;
    pointer-events: none;
    &::before {
      content: "";
      width: 118px;
      height: 24px;
      border: 0;
      position: absolute;
      top: 12px;
      right: 0;
      margin-top: -36px;
    }
    &::after {
      content: "";
      width: 0;
      height: 0;
      border: 12px solid transparent;
      @apply border-b-white;
      position: absolute;
      top: 12px;
      right: 21%;
      margin-top: -36px;
    }
    &:hover {
      z-index: 60;
      opacity: 1;
      pointer-events: initial;
    }
  }

  .icon:hover + .menus {
    z-index: 60;
    opacity: 1;
    pointer-events: initial;
  }

  @media (hover: none) and (pointer: coarse) {
    .menus:hover {
      transition: all;
      transition-delay: 100ms;
      opacity: 0;
      z-index: -1;
    }
  }
}
