// TODO: CLEAN/REWRITE
.ant-modal {
  .ant-modal-content {
    padding: 0;
    border-radius: 0;

    .ant-modal-body {
      @apply p-0;
    }

    .ant-modal-close {
      @apply mt-[0.4375rem] hover:bg-transparent;
    }

    .ant-modal-confirm-body-wrapper .ant-modal-confirm-title {
      @apply text-gray-600 border-b border-gray-200 m-0;
      padding: 24px 24px 20px 24px;
    }

    .ant-modal-confirm-body-wrapper .ant-modal-confirm-content {
      @apply text-base font-light text-gray-500 w-full max-w-full m-0;
      min-height: 88px;
    }
  }
}
