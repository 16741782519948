.ant-btn-default {
  @apply border-gray-300;
  &:not(:disabled):hover {
    color: inherit;
    @apply border-gray-400;
  }
  &:disabled {
    color: inherit;
    @apply bg-white opacity-40;
  }
}

.ant-btn-primary {
  @apply border-0 font-bold;
  &:not(:disabled):hover {
    @apply bg-blue-600;
  }

  &:disabled {
    @apply bg-blue-500 text-white opacity-40;
  }
}

.ant-btn.ant-btn-lg {
  @apply w-auto h-12 px-13 py-0 rounded-sm;
}
